import { Button } from '../../LegacyComponents/Button/Button';
import { Statement } from '../Statement';
import * as Styled from '../Statement.styled';

export const UnsupportedOrDisabledWebGl = () => (
  <Statement heading="Your browser disabled WebGL or WebGL is not supported.">
    <Styled.BodyText>
      We're sorry for the inconvenience, WebGL is required to use Newsec Maps.
    </Styled.BodyText>

    <Styled.BodyText>
      If possible, please ensure that you are running most up-to-date version of
      your browser and have the latest drivers for your video card.
    </Styled.BodyText>

    <Styled.BodyText>
      <Styled.Link
        href="https://www.wikihow.com/Enable-Webgl"
        rel="noopener"
        target="_blank"
      >
        Click here
      </Styled.Link>
      to learn more on how to enable WebGL in your browser.
    </Styled.BodyText>

    <br />
    <Button onClick={() => window.location.reload()}>Refresh</Button>
  </Statement>
);
