import firebase from 'firebase/compat/app';
import { getEnvVariable } from './shared/environment';

const firebaseConfig = {
  apiKey: getEnvVariable('REACT_APP_FIREBASE_API_KEY'),
  authDomain: getEnvVariable('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  databaseURL: getEnvVariable('REACT_APP_FIREBASE_DATABASE_URL'),
  projectId: getEnvVariable('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnvVariable('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnvVariable('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
