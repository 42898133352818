import { upperFirst } from 'lodash-es';
import { Statement } from '../Statement';
import * as Styled from '../Statement.styled';
import { getPrettyBrowserInfo } from './getPrettyBrowserInfo';

export const UnsupportedClient = () => {
  const browserInfo = getPrettyBrowserInfo();

  return (
    <Statement heading="Update your browser to continue using Newsec Maps">
      <Styled.BodyText>
        Unfortunately Newsec Maps no longer supports{' '}
        <Styled.BodyTextNoBreak as="span">
          {upperFirst(browserInfo.name)} {browserInfo.version}
        </Styled.BodyTextNoBreak>
        . For instructions on how to upgrade, visit your browser's help
        resources:
      </Styled.BodyText>

      <Styled.Ul>
        {browserLinks.map((link) => (
          <Styled.Li key={link.href}>
            <Styled.Link href={link.href} rel="noopener" target="_blank">
              {link.text}
            </Styled.Link>
          </Styled.Li>
        ))}
      </Styled.Ul>
    </Statement>
  );
};

const browserLinks = [
  { href: 'http://www.google.com/chrome', text: 'Chrome' },
  { href: 'http://www.mozilla.com/firefox/upgrade.html', text: 'Firefox' },
  {
    href: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
    text: 'Microsoft Edge',
  },
  { href: 'http://www.apple.com/safari/', text: 'Safari' },
  { href: 'http://www.opera.com/', text: 'Opera' },
];
