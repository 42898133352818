const requiredEnvs = [
  'REACT_APP_FIREBASE_API_KEY',
  'REACT_APP_FIREBASE_AUTH_DOMAIN',
  'REACT_APP_FIREBASE_DATABASE_URL',
  'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
  'REACT_APP_FIREBASE_PROJECT_ID',
  'REACT_APP_FIREBASE_STORAGE_BUCKET',
  'REACT_APP_GOOGLE_API_KEY',
  'REACT_APP_MAPBOX_API_ACCESS_TOKEN',
  'REACT_APP_NEWSEC_API_URL',
  'REACT_APP_WEBSITE_NAME',
  'REACT_APP_SEGMENT_KEY',
] as const;

function getHTMLEnv(key: string): string | undefined {
  const envsSerialized =
    document.querySelector<HTMLDivElement>('#envs')?.dataset.envs;

  if (!envsSerialized) {
    return undefined;
  }

  return JSON.parse(atob(envsSerialized))[key];
}

export function getEnvVariable(key: RequiredEnvsUnion): string;
export function getEnvVariable(key: string): string | undefined;
export function getEnvVariable(
  key: RequiredEnvsUnion | string,
): string | undefined {
  const value = getHTMLEnv(key) ?? process.env[key];

  if (isKeyRequired(key)) {
    if (value === undefined || value === '') {
      throw new Error(`${key} env is required`);
    }

    return value;
  }

  return value;
}

const isKeyRequired = (key: string): key is RequiredEnvsUnion => {
  const required: string[] = [...requiredEnvs];
  return required.includes(key);
};

type RequiredEnvsUnion = (typeof requiredEnvs)[number];

declare global {
  interface Window {
    envs?: Record<string, string | undefined>;
  }
}

const verifyRequiredEnvs = () => {
  if (process.env.NODE_ENV === 'test') return;
  requiredEnvs.forEach((key) => getEnvVariable(key));
};

verifyRequiredEnvs();

export const checkIfNotProduction = () => {
  const environment = getEnvVariable('REACT_APP_SENTRY_ENV');
  return (
    environment === 'local' ||
    environment === 'staging' ||
    environment === 'preview'
  );
};
