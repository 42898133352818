import { getIsClientSupported } from './shared/getIsClientSupported';
import { UnsupportedClient } from './Statement/UnsupportedClient/UnsupportedClient';
import { getIsWebGlSupported } from './shared/getIsWebGlSupported';
import { UnsupportedOrDisabledWebGl } from './Statement/UnsupportedOrDisabledWebGl/UnsupportedOrDisabledWebGl';
import { GeneralError } from './Statement/GeneralError/GeneralError';

export const getComponentToRender = async () => {
  if (!getIsClientSupported()) {
    return UnsupportedClient;
  }

  if (!getIsWebGlSupported()) {
    return UnsupportedOrDisabledWebGl;
  }

  try {
    return (await import('./Root')).Root;
  } catch (error) {
    console.log(error);

    return GeneralError;
  }
};
