import { browserName } from 'detect-browser';
import { unsupportedBrowsers } from './unsupportedBrowsers';

export const getIsClientSupported = (userAgent?: string) => {
  if (!userAgent) {
    userAgent = navigator.userAgent;
  }

  const maybeBrowserName = browserName(userAgent);

  /**
   * Why null or - in general - falsy value here?
   *
   * Even though we always use this app with browser
   * sometimes it might not be the case. Someone could try to access
   * the app with e.g. curl resulting in UA `curl/$version`, or
   * fetch with nodejs resulting in UA `Node.js/$version ($os)`.
   *
   * Knowing that, we only allow client when it's of type browser.
   *
   * @see detect-browser
   */
  if (!maybeBrowserName) {
    return false;
  }

  return !unsupportedBrowsers.includes(maybeBrowserName);
};
