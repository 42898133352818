import { migrateLegacyLocalStorage } from './migrateLegacyLocalStorage';
migrateLegacyLocalStorage();

import 'react-app-polyfill/stable';
import { getComponentToRender } from './getComponentToRender';
import './firebaseApp';
import './sentry';
import { unregisterServiceWorkers } from './unregisterServiceWorkers';
import { createRoot } from 'react-dom/client';
import { loadVitally } from './loadVitally';

loadVitally();

unregisterServiceWorkers();

const render = async () => {
  const Component = await getComponentToRender();

  const root = createRoot(document.getElementById('root')!);
  root.render(<Component />);
};

render();
