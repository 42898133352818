/* eslint-disable */
import { getEnvVariable } from './shared/environment';

export function loadVitally() {
  (function (n, t) {
    for (
      // @ts-expect-error
      var i = (n[t] = n[t] || []),
        // @ts-expect-error
        o = function (r) {
          i[r] =
            i[r] ||
            function () {
              for (var n = [], t = 0; t < arguments.length; t++)
                n[t] = arguments[t];
              return i.push([r, n]);
            };
        },
        u = 0,
        c = ['init', 'user', 'account', 'track', 'nps'];
      u < c.length;
      u++
    ) {
      o(c[u]);
    }
  })(window, 'Vitally');

  // @ts-expect-error
  Vitally.init(getEnvVariable('REACT_APP_VITALLY_KEY'));
}
