export const unregisterServiceWorkers = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      //returns installed service workers
      if (registrations.length) {
        for (const registration of registrations) {
          registration.unregister();
        }
      }
    });
  }
};
