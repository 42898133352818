export const getIsWebGlSupported = () => {
  const canvas = document.createElement('canvas');

  for (const name of webGlContextNames) {
    const context = canvas.getContext(name);

    if (
      context &&
      (context instanceof WebGLRenderingContext ||
        context instanceof WebGL2RenderingContext)
    ) {
      return true;
    }
  }

  return false;
};

const webGlContextNames = [
  'webgl2',
  'webgl',
  'experimental-webgl',
  'moz-webgl',
  'webkit-3d',
];
