import { Statement } from '../Statement';
import { Button } from '../../LegacyComponents/Button/Button';
import * as Styled from '../Statement.styled';

export const GeneralError = () => (
  <Statement heading="We're sorry — something's gone wrong">
    <Styled.BodyText>
      We've been notified. We are currently trying to fix the issue. <br />
      Please try again later.
    </Styled.BodyText>

    <br />

    <Button onClick={() => window.location.reload()}>Refresh</Button>
  </Statement>
);
