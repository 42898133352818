import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { getEnvVariable } from './shared/environment';

if (
  getEnvVariable('REACT_APP_SENTRY_URL') &&
  getEnvVariable('REACT_APP_SENTRY_ENV')
) {
  Sentry.init({
    dsn: getEnvVariable('REACT_APP_SENTRY_URL'),
    environment: getEnvVariable('REACT_APP_SENTRY_ENV'),
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}
