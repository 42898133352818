import * as React from 'react';
import { getEnvVariable } from '../shared/environment';
import * as Styled from './Statement.styled';
import logo from './newsec-maps-logo.png';

interface StatementProps {
  heading: string;
  /**
   * Content of the statement
   */
  children: React.ReactNode;
}

export const Statement = ({ heading, children }: StatementProps) => (
  <Styled.Container>
    <Styled.BackgroundImage />

    <Styled.LogoContainer>
      <Styled.Logo src={logo} alt={getEnvVariable('REACT_APP_WEBSITE_NAME')} />
    </Styled.LogoContainer>

    <Styled.Content>
      <Styled.Card>
        <Styled.CardContent>
          <Styled.Heading as="h1" fontSize={'1.25rem'} lineHeight={1.4}>
            {heading}
          </Styled.Heading>

          {children}
        </Styled.CardContent>

        <Styled.CardFooter>
          <Styled.Heading fontSize={'0.75rem'} lineHeight={1}>
            For more information contact:
          </Styled.Heading>

          <Styled.BodyText fontSize={'0.75rem'} lineHeight={0.9}>
            Andreas Vestavik
            <br />
            Head of sales
            <br />
            <Styled.BodyText as="a" href="mailto:OJD@newsec.no">
              andreas@newsecmaps.com
            </Styled.BodyText>
            <br />
            <Styled.BodyText as="a" href="tel:+4795720763">
              +47 92 46 90 34
            </Styled.BodyText>
          </Styled.BodyText>
        </Styled.CardFooter>
      </Styled.Card>
    </Styled.Content>
  </Styled.Container>
);
