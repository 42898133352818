export function migrateLegacyLocalStorage() {
  const legacyData = localStorage.getItem('recoil-persist');

  if (!legacyData) {
    return;
  }

  const parsedData = JSON.parse(legacyData) as Record<
    | 'userState'
    | 'mapThemeState'
    | 'dayTimeState'
    | 'languageState'
    | 'isBuilding3dActiveState'
    | 'isMajorOwnerActiveState'
    | 'isMapLabelsActiveState'
    | 'isMapCadastreKeyActiveState'
    | 'isMapCadastreBorderActiveState',
    unknown
  >;

  function migrateToKey(key: string, value: unknown) {
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`Migrated "${key}" to localStorage`);
    }
  }

  migrateToKey('currentUser', parsedData.userState);
  migrateToKey('mapTheme', parsedData.mapThemeState);
  migrateToKey('dayTime', parsedData.dayTimeState);
  migrateToKey('language', parsedData.languageState);
  migrateToKey(
    'isMapCadastreBorderActive',
    parsedData.isMapCadastreBorderActiveState,
  );
  migrateToKey(
    'isMapCadastreKeyActive',
    parsedData.isMapCadastreKeyActiveState,
  );
  migrateToKey('isMapLabelsActive', parsedData.isMapLabelsActiveState);
  migrateToKey('isMajorOwnerActive', parsedData.isMajorOwnerActiveState);

  localStorage.removeItem('recoil-persist');
}
