import { detect, BrowserInfo } from 'detect-browser';

export const getPrettyBrowserInfo = (browserInfo?: BrowserInfo) => {
  if (!browserInfo) {
    browserInfo = detect(navigator.userAgent) as BrowserInfo;
  }

  const name =
    browserInfo.name === 'ie' ? 'Internet Explorer' : browserInfo.name;

  return {
    ...browserInfo,
    name,
  };
};
