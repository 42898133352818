import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ButtonProps {
  children: ReactNode;
  leftIconSrc?: string;
  onClick: () => void;
}
export const Button = ({ children, leftIconSrc, onClick }: ButtonProps) => (
  <Container onClick={onClick}>
    {leftIconSrc && <LeftIcon src={leftIconSrc} />}
    <ChildrenWraper>{children}</ChildrenWraper>
  </Container>
);

const Container = styled.button`
  display: flex;
  align-items: center;

  height: 3.6rem;
  padding: 0 1.9rem;
  font-size: 1.4rem;

  border-style: none;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  background: #4b4f5b;
  color: #fff;
  cursor: pointer;
`;

const LeftIcon = styled.img`
  margin-right: 5px;
`;

const ChildrenWraper = styled.div`
  display: inline-flex;
`;
